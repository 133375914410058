<template>
  <div class="recommendPage">
    <div class="button-prev" slot="button-prev">
      <!-- <img src="../assets/img/school/Icon_arrow_L.png" alt="" /> -->
    </div>
    <swiper :options="swiperOption" ref="mySwiper"  class="show-swiper">
      <swiper-slide
        v-for="(item, index) in list"
        :key="index"
        @mouseover.native="enters(index)"
        @mouseleave.native="leaver(index)"
      >
        <div class="my-swiper-item">
            <img :src="item.imageUrl"  />
            <div class="swiper-text" >
            <div class="swiper-text-box">
                <h2 class="swiper-text-title">{{ item.imageTital }}</h2>
                <p class="swiper-text-con" :title="item.imageDesc">{{ item.imageDesc }}</p>
            </div>
            </div>
        </div>
        
      </swiper-slide>
    </swiper>
    <div class="button-next" slot="button-next">
      <!-- <img src="../assets/img/school/Icon_arrow_R.png" alt="" /> -->
    </div>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  name: "Home",
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    list: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      isText: "",
      swiperOption: {
        slidesPerView:'auto',
        watchSlidesProgress: true,
        // 默认active slide居左，设置为true后居中
        centeredSlides: true,
        direction: "horizontal", // 垂直切换选项
        loop: true,
        navigation: {
          nextEl: ".button-next",
          prevEl: ".button-prev",
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        on: {
            progress: function() {
                for (let i = 0; i < this.slides.length; i++) {
                    const slide = this.slides.eq(i) // 指定匹配元素集缩减值
                    const slideProgress = this.slides[i].progress // 当前元素集的progress值
                    let modify = 0 // 偏移权重
                    if (Math.round(Math.abs(slideProgress)) > 0) {
                        modify = Math.abs(slideProgress) * 0.2 // 不一定要0.2，可自行调整
                    }
                    const translate = slideProgress * modify * 800 + 'px' // 500是swiper-slide的宽度
                    const scale = 1 - Math.abs(slideProgress) / 5 // 缩放权重值，随着progress由中向两边依次递减，可自行调整
                    const zIndex = 99 - Math.abs(Math.round(10 * slideProgress))
                    slide.transform(`translateX(${translate}) scale(${scale})`)
                    slide.css('zIndex', zIndex)
                    slide.css('opacity', 1) // 是否可见
                    if (Math.round(Math.abs(slideProgress)) > 1) { // 设置了只有选中的元素以及他两遍的显示，其他隐藏
                        slide.css('opacity', 0)
                    }else if(Math.round(Math.abs(slideProgress)) == 1) { // 设置了只有选中的元素以及他两遍的显示，其他隐藏
                        slide.css('opacity', 0.4)
                    }
                }
            }
        }
      },
    };
  },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.swiper;
    // },
  },
  methods: {
    enters() {
        this.$refs.mySwiper.swiper.autoplay.stop()
    },
    leaver() {
        this.$refs.mySwiper.swiper.autoplay.start()
    },
  },
  mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    console.log("this is current swiper instance object", this.list);
    // this.swiper.slideTo(3, 1000, false);
  },
};
</script>

<style scoped lang="scss">
.recommendPage {
  margin-top:20px;
  width: 100%;
  display: flex;
  align-items: center;
}
.recommendPage img {
  width: 1048px;
  height: 348px;
}
.recommendPage .swiper-container {
  position: relative;
  width: 1296px;
  height: 348px;
}
.recommendPage .swiper-container .swiper-slide {
  width: 1048px !important;
  height: 348px;
}

/* .recommendPage {
 width: 100%;
 height: 100%;
 position: relative;
 overflow: hidden;
 margin: 0 auto;
 padding: 50px 0;
    .show-swiper {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .swiper-slide {
            width: 500px;
            transition: all .4s cubic-bezier(.4, 0, .2, 1);
        }
    }
} */


.button-prev img,
.button-next img {
  width: 47px;
  height: 54px;
}
.button-prev {
  padding-right: 50px;
  width: 47px;
  height: 54px;
  cursor: pointer;
  background: url('../assets/img/school/Icon_arrow_L.png') no-repeat;
  background-size: 47px 54px;
}
.button-prev:hover{
  background: url('../assets/img/newschool/Icon_Arrow_L_B_H.png') no-repeat;
  background-size: 47px 54px;
}
.button-next {
  padding-left: 50px;
  width: 47px;
  height: 54px;
  cursor: pointer;
  background: url('../assets/img/school/Icon_arrow_R.png') no-repeat right 0;
  background-size: 47px 54px;
}
.button-next:hover{
  background: url('../assets/img/newschool/Icon_Arrow_R_B_H.png') no-repeat right 0;
  background-size: 47px 54px;
}
.swiper-slide-active .my-swiper-item:hover{
    // display: block;
    .swiper-text{
        display: block;
    }
}
.swiper-text {
    display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 141px;
  background: rgba(255,255,255,0.8);
  box-shadow: 0px 0px 18px 0px rgba(109, 109, 109, 0.15);
  border-radius: 2px;
}
.swiper-text-box {
  padding: 18px 22px;
}
.swiper-text-title {
  padding-left:15px;
  background:url('../assets/img/newschool/Pic_XYFC_t.png') no-repeat left 6px;
  background-size: 4px 16px;
  font-size: 20px;
  font-weight: bold;
  color: #001827;
}
.swiper-text-con {
  margin-top:14px;
  font-size: 16px;
  font-weight: normal;
  color: #001827;
  line-height: 24px;
  word-break: break-all;
  overflow:hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 这个表示要显示几行 */
  -webkit-box-orient: vertical;
}
</style>