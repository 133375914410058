<template>
  <div class="school">
    <div
      v-if="$store.state.userInfo.superviseId"
      style="
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 999999;
        left: 0;
        top: 0.68rem;
      "
    ></div>
    <div class="school-content">
      <!-- 在线课堂 -->
      <div class="online-class">
        <div class="main-box">
          <div class="swiper-title online-tit">
            <h2>在线课堂</h2>
          </div>
          <videoList :isHome="true" />
        </div>
      </div>
      <!-- 校园风采 -->
      <div class="swiper">
        <div class="main-box">
          <div class="swiper-title-h school-style">
            <h2>校园风采</h2>
          </div>
          <swiper :list="swiperList" v-if="swiperList.length"></swiper>
          <!-- <swiperNull v-else /> -->
        </div>
      </div>
      <!-- 师资力量 -->
      <div class="teacher">
        <div class="main-box">
          <div class="swiper-title online-tit">
            <h2>师资力量</h2>
          </div>
          <div class="teacher-box">
            <vue-seamless-scroll
              class="teacher-box-scroll"
              :data="tecEndList"
              :class-option="classOption"
              v-if="tecEndList.length > 3"
            >
              <div class="teacher-list">
                <div
                  class="teacher-item"
                  v-for="(item, index) in tecEndList"
                  :key="index"
                  :class="{
                    teacherMl: index == 0,
                    teacherMr: index == tecEndList.length - 1,
                  }"
                >
                  <div class="teacher-tx" v-if="item.photo != ''">
                    <img :src="constant.URL + item.photo" alt="" />
                    <h2 class="name">
                      <span :title="item.name">{{
                        item.name != "" ? item.name : "教师姓名"
                      }}</span>
                    </h2>
                  </div>
                  <div class="teacher-tx" v-else>
                    <img
                      src="../../assets/img/newschool/Pic_Sy_Teacher_Ks.png"
                      alt=""
                    />
                    <h2 class="name">
                      <span>{{
                        item.name != "" ? item.name : "教师姓名"
                      }}</span>
                    </h2>
                  </div>
                  <div class="teacher-detail">
                    <p class="bs">
                      <span>{{
                        item.sex === 1 ? "男" : item.sex === 2 ? "女" : "性别"
                      }}</span>
                      <span>{{ item.degreeName || "暂无数据" }}</span>
                    </p>
                    <!-- <p class="banji">
                      {{ item.degree != "" ? item.degree : "暂无数据" }}
                    </p> -->
                    <p class="txt" :title="item.intro">
                      {{ item.intro != "" ? item.intro : "暂无数据" }}
                    </p>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
            <div class="teacher-list" v-else>
              <div
                class="teacher-item"
                v-for="(item, index) in tecEndList"
                :key="index"
              >
                <div class="teacher-tx" v-if="item.photo != ''">
                  <img :src="constant.URL + item.photo" alt="" />
                  <h2 class="name">
                    <span :title="item.name">{{
                      item.name != "" ? item.name : "教师姓名"
                    }}</span>
                  </h2>
                </div>
                <div class="teacher-tx" v-else>
                  <img
                    src="../../assets/img/newschool/Pic_Sy_Teacher_Ks.png"
                    alt=""
                  />
                  <h2 class="name">
                    <span>{{ item.name != "" ? item.name : "教师姓名" }}</span>
                  </h2>
                </div>
                <div class="teacher-detail">
                  <p class="bs">
                    <span>{{
                      item.sex === 1 ? "男" : item.sex === 0 ? "女" : "性别"
                    }}</span>
                    <span>{{ item.degreeName || "暂无数据" }}</span>
                  </p>
                  <!-- <p class="banji">
                    {{ item.degree != "" ? item.degree : "暂无数据" }}
                  </p> -->
                  <p class="txt">
                    {{ item.intro != "" ? item.intro : "暂无数据" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="plaque">
        <div class="main-box">
          <div class="swiper-title-h plaque-tit">
            <h2>教学实力</h2>
          </div>
          <div class="teacher-box">
            <vue-seamless-scroll
              class="teacher-box-scroll"
              :data="tecList"
              :class-option="plaqueOption"
              v-if="tecList.length > 4"
            >
              <div class="plaque-list">
                <ul>
                  <li v-for="(item, index) in tecList" :key="index">
                    <div class="plaque-list-img">
                      <img
                        :src="constant.URL + item.imageUrl"
                        alt=""
                        class="null"
                        v-if="item.imageUrl != ''"
                      />
                      <img
                        src="../../assets/img/school/run_nullpic.png"
                        alt=""
                        class="null"
                        v-else
                      />
                    </div>
                    <p class="plaque-title">
                      <span :class="item.imageTital != '' ? '' : 'req'">{{
                        item.imageTital != "" ? item.imageTital : "暂无荣誉资料"
                      }}</span>
                    </p>
                    <p class="plaque-miaoshu" v-if="item.imageDesc != ''">
                      {{ item.imageDesc }}
                    </p>
                  </li>
                </ul>
              </div>
            </vue-seamless-scroll>
            <div class="plaque-list" v-else>
              <ul>
                <li v-for="(item, index) in tecList" :key="index">
                  <div class="plaque-list-img">
                    <img
                      :src="constant.URL + item.imageUrl"
                      alt=""
                      class="null"
                      v-if="item.imageUrl != ''"
                    />
                    <img
                      src="../../assets/img/school/run_nullpic.png"
                      alt=""
                      class="null"
                      v-else
                    />
                  </div>
                  <p class="plaque-title">
                    <span :class="item.imageTital != '' ? '' : 'req'">{{
                      item.imageTital != "" ? item.imageTital : "暂无荣誉资料"
                    }}</span>
                  </p>
                  <p class="plaque-miaoshu" v-if="item.imageDesc != ''">
                    {{ item.imageDesc }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videoList from "@/components/school/videoList.vue";
import swiper from "@/components/swiper3d1.vue";
// import swiperNull from "@/components/swiperNull.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
//import carousel from "@/components/swiper3.vue";
export default {
  data() {
    return {
      swiperList: [],
      tecList: [],
      tecEndList: [],
      imgNull: [
        require("@/assets/img/newschool/Pic_Sy_Fc_a.png"),
        require("@/assets/img/newschool/Pic_Sy_Fc_b.png"),
        require("@/assets/img/newschool/Pic_Sy_Fc_c.png"),
        require("@/assets/img/newschool/Pic_Sy_Fc_d.png"),
      ],
    };
  },
  watch: {
    "$store.state.schoolInfo.atteSchool.id"(newvalue) {
      this.findSchoolAnnex(newvalue);
      this.findSchoolCredit(newvalue);
      this.findSchoolFaculty(newvalue);
    },
  },
  mounted() {
    console.log(this.$store.state.schoolInfo);

    let schoolId = this.$store.state.schoolInfo.atteSchool.id;
    this.findSchoolAnnex(schoolId);
    this.findSchoolCredit(schoolId);
    this.findSchoolFaculty(schoolId);
  },
  computed: {
    classOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: this.tecEndList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    plaqueOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: this.tecEndList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    // 校园风采
    findSchoolAnnex(id) {
      this.api.school.findSchoolAnnex(id).then((res) => {
        console.log(res, "校园风采");
        if (res.code == 20000) {
          res.data.forEach((item) => {
            item.imageUrl = this.constant.URL + item.imageUrl;
          });
          console.log(this.swiperList.length, "this.swiperList.length");
          if (res.data.length < 4) {
            let len = res.data.length;

            for (let i = len; i < 4; i++) {
              res.data.push({
                imageUrl: this.imgNull[i - len],
                imageTital: "暂无数据",
                imageDesc: "暂无数据",
              });
            }
          }
          this.swiperList = res.data;
        }
      });
    },
    // 教师实力
    findSchoolCredit(id) {
      this.api.school.findSchoolCredit(id).then((res) => {
        console.log(res);
        if (res.flag) {
          this.tecList = res.data.filter((res) => {
            return res.id;
          });
          for (let i = this.tecList.length; i < 4; i++) {
            this.tecList.push({
              imageUrl: "",
              imageTital: "暂无数据",
              imageDesc: "暂无数据",
            });
          }
        }
      });
    },

    // 师资力量
    findSchoolFaculty(id) {
      this.api.school.findSchoolFaculty(id).then((res) => {
        console.log("教学力量", res);
        if (res.flag) {
          this.tecEndList = res.data;
          if (this.tecEndList.length < 3) {
            let len = this.tecEndList.length;
            for (let i = len; i < 3; i++) {
              this.tecEndList.push({
                degree: "",
                degreeName: "",
                deletetime: "",
                intro: "",
                level: "",
                levelName: "",
                masterGrade: "",
                name: "",
                photo: "",
                publish: "",
                publishTime: "",
                sex: "",
              });
            }
          }
          console.log(this.tecEndList, "this.swiperList");
        }
      });
    },
  },
  components: {
    swiper,
    videoList,
    // swiperNull,
    vueSeamlessScroll,
    //carousel,
  },
};
</script>

<style lang="scss" scoped>
.online-class {
  // padding: 40px 0;
  padding-top: 35px;
  height: 346px;
  background: url("../../assets/img/newschool/Pic_Bg_ZXKT.png") no-repeat;
  background-size: 100% 100%;
  .online-tit {
    width: 361px;
    background: url("../../assets/img/newschool/Pic_Zs_ZXKT.png") no-repeat left
      center;
    background-size: 100% auto;
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
  }
}
.teacher {
  padding-top: 35px;
  height: 346px;
  background: url("../../assets/img/newschool/Pic_Bg_SZLL.png") no-repeat;
  background-size: 100% 100%;
  .online-tit {
    width: 361px;
    background: url("../../assets/img/newschool/Pic_Zs_SZLL.png") no-repeat left
      center;
    background-size: 100% auto;
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
  }
}

.plaque,
.swiper {
  background: #f1f1f1;
  padding: 40px 0;
}
.main-box {
  width: 1434px;
  margin: 0 auto;
}
.swiper-title {
  width: 235px;
  height: 49px;
  background: url(../../assets/img/icon_bt.png) no-repeat;
  margin: 0 auto;
  padding-bottom: 25px;
  h2 {
    font-size: 36px;
    text-align: center;
    font-weight: bold;
    line-height: 49px;
  }
}
.swiper-title-h {
  height: 49px;
  margin: 0 auto;
  padding-bottom: 25px;
  h2 {
    font-size: 36px;
    text-align: center;
    font-weight: bold;
    line-height: 49px;
  }
}
.school-style {
  width: 340px;
  background: url(../../assets/img/newschool/Pic_Zs_XYFC.png) no-repeat left
    15px;
  background-size: 100% auto;
}
.plaque-tit {
  width: 316px;
  background: url(../../assets/img/newschool/Pic_ZS_JXSL.png) no-repeat left
    15px;
  background-size: 100% auto;
}
.teacher-box {
  overflow: hidden;
  // width:1434px;
}
/deep/.teacher-box-scroll > div {
  width: 99999px !important;
}
.teacher-list {
  width: 1923px;
  margin-top: 20px;
  position: relative;
  @include flex(row, flex-start, center);
  .teacherMl {
    margin-left: 34px;
  }
  .teacherMr {
    margin-right: 0;
  }
  .teacher-item {
    // width: 48%;
    // display: flex;
    position: absolute;
    margin-right: 34px;
    width: 455px;
    height: 208px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    @include flex(row, space-between, top);
    position: relative;
    .teacher-tx {
      padding: 20px 0 0 20px;
      width: 130px;
      height: 179px;
      border-radius: 6px;
      // background: url(../../assets/img/school/tec_bg.png) no-repeat;
      background-size: 100% 100%;

      img {
        width: 130px;
        height: 179px;
      }
      .name {
        position: absolute;
        left: -2px;
        bottom: 0;
        width: 457px;
        height: 47px;
        line-height: 47px;
        background: url("../../assets/img/newschool/Pic_Bz_Teacher.png")
          no-repeat;
        background-size: cover;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;

        > span {
          display: inline-block;
          width: 170px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .teacher-detail {
      width: 352px;
      margin-left: 21px;
      margin-right: 42px;
      .bs {
        margin-top: 20px;
        span {
          margin-right: 17px;
          display: inline-block;
          width: 71px;
          height: 20px;
          padding: 2px 5px;
          background: url("../../assets/img/newschool/Pic_Bg_JSBQ.png")
            no-repeat;
          background-size: cover;
          margin-right: 10px;
          font-size: 16px;
          color: #333333;
        }
      }
      .banji {
        margin-top: 10px;
        font-size: 16px;
        color: #333;
      }
      .txt {
        height: 116px;
        font-size: 16px;
        color: #888;
        padding-top: 10px;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 5; /* 这个表示要显示几行 */
        -webkit-box-orient: vertical;

        // white-space: nowrap;
      }
    }
  }
}
.plaque-list {
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    li {
      text-align: center;
      flex: 1;
      .plaque-list-img {
        margin: 0 auto;
        width: 318px;
        height: 206px;
        background: url(../../assets/img/newschool/Pic_Bg_ZZBK.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // border-radius:4px;
      }
      .null {
        margin-top: -5px;
        width: 278px;
        height: 161px;
      }
      img {
        width: 278px;
        height: 161px;
        border-radius: 4px;
      }
      .plaque-title {
        font-size: 20px;
        color: #333;
        font-weight: bold;
      }

      .plaque-miaoshu {
        font-size: 16px;
        color: #888;
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 5; /* 这个表示要显示几行 */
        -webkit-box-orient: vertical;
      }
    }
  }
}
.req {
  color: #888888;
}
.null {
  color: #888888;
}
</style>